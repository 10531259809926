import React from 'react';
import Footer from "./footer";

import Header from "./header";
import ReactGA from "react-ga4";

ReactGA.send(window.location.pathname);

function Home() {
    return (<>
        <Header />

        <div className="   mx-auto max-w-screen-xl px-4 py-10 lg:flex lg:h-screen lg:items-center">
            <div className="flex flex-col justify-between  lg:flex-row">
                <div className="mb-12 lg:max-w-lg lg:pr-5 lg:mb-0">
                    <div className="max-w-xl mb-6">
                        <h2 className="text-5xl bg-gradient-to-r  from-green-400 via-blue-800 to-purple-800 bg-clip-text  font-extrabold text-transparent  max-w-lg mb-6 font-sans  tracking-tight  sm:text-5xl sm:leading-none">
                            Unit 326.

                        </h2>
                        <p className=" text-gray-600 text-2xl">
                           User-centric software solutions by true builders and creatives
                        </p>
                    </div>
                    <hr className="mb-6 border-gray-300" />

                </div>

            </div>
        </div>



        <div className=" bg-gray-50 p-20">
            <div className="max-w-xl mb10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">

                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          <span className="relative inline-block">
            <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                    id="18302e52-9e2a-4c8e-9550-0cbb21b38e55"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                >
                  <circle cx="1" cy="1" r=".7" />
                </pattern>
              </defs>
              <rect
                  fill="url(#18302e52-9e2a-4c8e-9550-0cbb21b38e55)"
                  width="52"
                  height="24"
              />
            </svg>
            <span className="relative">Products.</span>
          </span>
                </h2>

            </div>
            <div className="grid gap-2 row-gap-5   sm:grid-cols-2 lg:grid-cols-2">

                <div className="flex flex-col w-60 justify-between p-5 border rounded-xl bg-gray-100 shadow-2xl">
                    <div>

                        <a target={'_blank'} href="https://www.treplo.studio/" className={'text-decoration-none '}>
                            <h6 className="mb-2 font-semibold text-lg bg-gradient-to-r from-green-400 via-blue-800 to-purple-800 bg-clip-text  font-extrabold text-transparent  leading-5">Treplo Studio</h6>
                        </a>
                        <p className="mb-3 text-sm text-gray-900">
                          Securely manage all your patient data
                        </p>
                    </div>
                    <a
                        target={'_blank'} href="https://www.treplo.studio/"
                        aria-label=""
                        className="text-decoration-none inline-flex items-center text-decoration-none font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
                    >
                        Learn more →
                    </a>
                </div>
                <div className="flex flex-col w-60 justify-between p-5 border rounded-xl bg-gray-100  shadow-2xl">
                    <div>

                        <a href={'hiking-trails-za'} className={'text-decoration-none'}>
                            <h6 className="mb-2 font-semibold text-lg bg-gradient-to-r from-green-400 via-blue-800 to-purple-800 bg-clip-text  font-extrabold text-transparent  leading-5">Bundu</h6>
                        </a>
                        <p className="mb-3 text-sm text-gray-900">
                          Find the most breath-taking hiking trails in South Africa
                        </p>
                    </div>
                    <a
                        href="hiking-trails-za"
                        aria-label=""
                        className="inline-flex items-center text-decoration-none font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
                    >
                        Learn more →
                    </a>
                </div>

            </div>
        </div>




        <Footer />


    </>);
}

export default Home;
