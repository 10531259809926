import React from 'react';
import logo from '../assets/logo.png'

function HTZAPolicy() {
    return (<>

        <div className="container p-4">
            <p>
                <h3> Bundu | Hiking Trails ZA - Data and Privacy Policy</h3>

                Effective Date: 02 October 2023
                <br /><br />
                Welcome to Bundu | Hiking Trails ZA, a hiking app created by Unit 326. This policy explains the collection, use,
                and protection of your data when you use our app. We are committed to safeguarding your privacy and
                ensuring a secure and enjoyable user experience.
                <br /><br />

                <h3> Interpretation and Definitions</h3> <br />

                <h5> Interpretation </h5><br />
                <p>The words of which the initial letter is capitalized have meanings defined under the following
                    conditions. The following definitions shall have the same meaning regardless of whether they appear
                    in singular or in plural.</p>

                <h6>Definitions</h6>
                <p>For the purposes of this Privacy Policy:</p>
                <ul>
                    <li>
                        <p><strong>Account</strong> means a unique account created for You to access our Service or
                            parts of our Service.</p>
                    </li>
                    <li>
                        <p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common
                            control with a party, where "control" means ownership of 50% or more of the shares, equity
                            interest or other securities entitled to vote for election of directors or other managing
                            authority.</p>
                    </li>
                    <li>
                        <p><strong>Application</strong> refers to Bundu | Hiking Trails ZA, the software program provided by the
                            Company.</p>
                    </li>
                    <li>
                        <p><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this
                            Agreement) refers to Unit 326, Bryanston South Africa.</p>
                    </li>
                    <li>
                        <p><strong>Country</strong> refers to: South Africa</p>
                    </li>
                    <li>
                        <p><strong>Device</strong> means any device that can access the Service such as a computer, a
                            cellphone or a digital tablet.</p>
                    </li>
                    <li>
                        <p><strong>Personal Data</strong> is any information that relates to an identified or
                            identifiable individual.</p>
                    </li>
                    <li>
                        <p><strong>Service</strong> refers to the Application.</p>
                    </li>
                    <li>
                        <p><strong>Service Provider</strong> means any natural or legal person who processes the data on
                            behalf of the Company. It refers to third-party companies or individuals employed by the
                            Company to facilitate the Service, to provide the Service on behalf of the Company, to
                            perform services related to the Service or to assist the Company in analyzing how the
                            Service is used.</p>
                    </li>
                    <li>
                        <p><strong>Usage Data</strong> refers to data collected automatically, either generated by the
                            use of the Service or from the Service infrastructure itself (for example, the duration of a
                            page visit).</p>
                    </li>
                    <li>
                        <p><strong>You</strong> means the individual accessing or using the Service, or the company, or
                            other legal entity on behalf of which such individual is accessing or using the Service, as
                            applicable.</p>
                    </li>
                </ul>


                <br /><br />


                <h3> 1. Collection and Usage of Personal Data</h3> <br />

                <h4> Types of Data Collected </h4><br />
                <h5>Personal Data</h5>
                <p>While using Our Service, We may ask You to provide Us with certain personally identifiable
                    information that can be used to contact or identify You. Personally identifiable information may
                    include, but is not limited to:

                    <ul>
                        <li> Email address</li>
                        <li> First name and last name</li>
                        <li> Usage Data</li>
                        <li>Primary Account Details</li>
                    </ul>
                </p>
                <br />

                <h5> Usage Data </h5>
                <p>Usage Data is collected automatically when using the Service.</p>

                <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address),
                    browser type, browser version, the pages of our Service that You visit, the time and date of Your
                    visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>

                <p>When You access the Service by or through a mobile device, We may collect certain information
                    automatically, including, but not limited to, the type of mobile device You use, Your mobile device
                    unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile
                    Internet browser You use, unique device identifiers and other diagnostic data.</p>
                <p>We may also collect information that Your browser sends whenever You visit our Service or when You
                    access the Service by or through a mobile device.</p>


                <br />


                <h5> 1. Data We Collect</h5>
                Our app collects location data to record your workouts (such as runs, walks, and bike rides) and provide
                features like route tracking, distance measurement, and workout analysis. This data is collected in the
                following ways:
                <br/>

                <b>While using the app:</b> Location data is recorded when the app is actively being used.
                <br/>  <b> In the background:</b> Location data is collected when workouts continue after the app is minimized,
                provided you have granted permission for background location access.

                <br /><br />
                <h5> 2. Why We Collect Background Location Data</h5>
                We collect background location data to:

                <li> Accurately track the entirety of your workout, even when the app is not in the foreground.</li>
                <li> Provide post-workout summaries, including maps of your route, distance covered, speed, and
                    elevation changes.
                </li>
                <li> Ensure uninterrupted recording during extended workouts.</li>

                <br />
                <h5> 3. How We Use Your Location Data</h5>
                Your background location data is used for:
                <br />
                <ul>
                    <li> Generating workout statistics and visual maps.</li>
                    <li> Personalizing your workout history and fitness goals.</li>
                    <li> Enhancing app features and improving user experience.</li>
                    <li> We never sell your location data to third parties.</li>
                </ul>
                <br />

                <h5> 4. How We Protect Your Data</h5>
                We take the security of your data seriously and implement the following measures:

                <br />
                <ul>
                    <li> Encrypting data in transit and at rest.</li>
                    <li> Limiting access to your data to authorized personnel only.</li>
                    <li> Complying with applicable data protection regulations, such as the GDPR and CCPA.</li>
                </ul>

                <br />

                <h5> 5. Your Privacy Choices</h5>
                You control how and when we access your location data:

                <ul>
                    <li> You can grant or deny background location permissions at any time through your device’s
                        settings.
                    </li>
                    <li> You can delete your workout data through the app at any time.</li>
                    <li> Note: Disabling background location permissions may result in incomplete workout tracking and
                        reduced functionality.
                    </li>

                </ul>


                <p>You can enable or disable access to this information at any time, through Your Device settings.</p>


                <br /><br />

                <h4> Use of Your Personal Data</h4>
                <p>The Company may use Personal Data for the following purposes:</p>

                <ul>
                    <li>
                        <p><strong>To provide and maintain our Service</strong>, including to monitor the usage of our
                            Service.</p>
                    </li>
                    <li>
                        <p><strong>To manage Your Account:</strong> to manage Your registration as a user of the
                            Service. The Personal Data You provide can give You access to different functionalities of
                            the Service that are available to You as a registered user.</p>
                    </li>
                    <li>
                        <p><strong>For the performance of a contract:</strong> the development, compliance and
                            undertaking of the purchase contract for the products, items or services You have purchased
                            or of any other contract with Us through the Service.</p>
                    </li>
                    <li>
                        <p><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other
                            equivalent forms of electronic communication, such as a mobile application's push
                            notifications regarding updates or informative communications related to the
                            functionalities, products or contracted services, including the security updates, when
                            necessary or reasonable for their implementation.</p>
                    </li>
                    <li>
                        <p><strong>To provide You</strong> with news, special offers and general information about other
                            goods, services and events which we offer that are similar to those that you have already
                            purchased or enquired about unless You have opted not to receive such information.</p>
                    </li>
                    <li>
                        <p><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</p>
                    </li>
                    <li>
                        <p><strong>For business transfers:</strong> We may use Your information to evaluate or conduct a
                            merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer
                            of some or all of Our assets, whether as a going concern or as part of bankruptcy,
                            liquidation, or similar proceeding, in which Personal Data held by Us about our Service
                            users is among the assets transferred.</p>
                    </li>
                    <li>
                        <p><strong>For other purposes</strong>: We may use Your information for other purposes, such as
                            data analysis, identifying usage trends, determining the effectiveness of our promotional
                            campaigns and to evaluate and improve our Service, products, services, marketing and your
                            experience.</p>
                    </li>
                </ul>


                <p>We may share Your personal information in the following situations:</p>

                <ul>
                    <li><strong>With Service Providers:</strong> We may share Your personal information with Service
                        Providers to monitor and analyze the use of our Service, to contact You.
                    </li>
                    <li><strong>For business transfers:</strong> We may share or transfer Your personal information in
                        connection with, or during negotiations of, any merger, sale of Company assets, financing, or
                        acquisition of all or a portion of Our business to another company.
                    </li>
                    <li><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which
                        case we will require those affiliates to honor this Privacy Policy. Affiliates include Our
                        parent company and any other subsidiaries, joint venture partners or other companies that We
                        control or that are under common control with Us.
                    </li>
                    <li><strong>With business partners:</strong> We may share Your information with Our business
                        partners to offer You certain products, services or promotions.
                    </li>
                    <li><strong>With other users:</strong> when You share personal information or otherwise interact in
                        the public areas with other users, such information may be viewed by all users and may be
                        publicly distributed outside.
                    </li>
                    <li><strong>With Your consent</strong>: We may disclose Your personal information for any other
                        purpose with Your consent.
                    </li>
                </ul>


                <h5> Retention of Your Personal Data</h5>
                <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out
                    in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply
                    with our legal obligations (for example, if we are required to retain your data to comply with
                    applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
                <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally
                    retained for a shorter period of time, except when this data is used to strengthen the security or
                    to improve the functionality of Our Service, or We are legally obligated to retain this data for
                    longer time periods.</p>

                <br />
                <h5>Transfer of Your Personal Data</h5>
                <p>Your information, including Personal Data, is processed at the Company's operating offices and in any
                    other places where the parties involved in the processing are located. It means that this
                    information may be transferred to — and maintained on — computers located outside of Your state,
                    province, country or other governmental jurisdiction where the data protection laws may differ than
                    those from Your jurisdiction.</p>

                <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your
                    agreement to that transfer.</p>
                <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and
                    in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an
                    organization or a country unless there are adequate controls in place including the security of Your
                    data and other personal information.</p>

                <br />
                <h5>Delete Your Personal Data</h5>
                <p>You have the right to delete or request that We assist in deleting the Personal Data that We have
                    collected about You.</p>
                <p>Our Service may give You the ability to delete certain information about You from within the
                    Service.</p>
                <p>You may update, amend, or delete Your information at any time by signing in to Your Account, if you
                    have one, and visiting the account settings section that allows you to manage Your personal
                    information or visiting https://unit326.com/data-settings. You may also contact Us to request access
                    to, correct, or delete any personal information that You have provided to Us.</p>
                <p>Please note, however, that We may need to retain certain information when we have a legal obligation
                    or lawful basis to do so.</p>


                <br /> <br />
                <h3> 2. Data Security and Storage: </h3>
                <br />
                We employ industry-standard security measures to protect your personal information. Access to your data
                is restricted to authorized personnel and is stored securely on our servers.
                <br /><br />

                <h3> 3. Third-Party Services: </h3>
                <br />
                Bundu | Hiking Trails ZA allows you to log in using your Gmail and Apple account credentials. Please refer to
                their respective privacy policies for information on how they handle your data.
                <br /><br />


                <h3> 4. Marketing and Communications: </h3><br />
                We may use your email address to send you updates, newsletters, or promotional offers. You can opt-out
                of these communications at any time by following the unsubscribe instructions provided in the email.
                <br /><br /> <br />
                <h3> 5. User Control and Data Access: </h3><br />
                You can access, modify, or delete your personal information by accessing the app's settings. If you wish
                to deactivate your account, please contact us at hello@unit326.com.
                <p> or visit our contact page <a href={'https://unit326.com/data-settings'}>here</a></p>

                <br /><br />
                <h3> 6. Children's Privacy: </h3><br />
                Bundu | Hiking Trails ZA is not intended for individuals under the age of 13. We do not knowingly collect
                personal information from children. If you believe a child has provided us with their personal
                information, please contact us to have it removed.
                <br /><br />
                <h3> 8. Changes to this Privacy Policy: </h3><br />
                We reserve the right to modify this policy at any time. Changes will be effective upon posting on the
                app. It is your responsibility to review this policy periodically for updates.
                <br /><br />
                <h3> 7. Contact Us: </h3><br />
                If you have any questions or concerns about this Privacy Policy, please contact us at hello@unit326.com
                <br /><br />
                Thank you for choosing Bundu | Hiking Trails ZA!
                <br /><br />
                Unit 326 <br />
            </p>
        </div>

    </>);
}

export default HTZAPolicy;
