import React from 'react';

function Footer() {
    return (<>


        <footer className="shadow-2xl border-top border-gray-400 bg-white h-100 p-10 ">

            <section className="">
                <div className="text-md-start ">
                    <div className="row ">
                        <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">

                            <h6 className="text-uppercase text-black fw-bold mb-1">
                            <a className={'text-decoration-none text-black'} href={'/'}> Unit 326.</a>
                            </h6>

                            <p className={'text-black text-sm'}>
                                Builders. Creatives.
                            </p>
                        </div>

                        <div className="text-black  col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

                            <h6 className="text-uppercase fw-bold mb-1">
                                Socials
                            </h6>
                            <a target="_blank" href="https://twitter.com/Unit_326">
                                <i className="fa fa-twitter me-3 text-black "></i>

                            </a>
                        </div>

                        <div className=" text-black col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-1">

                            <h6 className="text-uppercase fw-bold mb-1">Contact</h6>
                            <p><i className="fa fa-home me-3 text-black "></i> Bryanston, South Africa</p>
                            <p>
                                <i className="fa fa-envelope me-3 text-black "></i>
                                hello@unit326.com
                            </p>
                        </div>
                    </div>

                </div>
            </section>
            <hr className={'text-black'}/>
            <div className="text-center text-black  p-4">
                © 2024 Unit 326 All rights reserved.
            </div>
        </footer>


    </>);
}

export default Footer;
